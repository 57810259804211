import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    Button,
    Table
} from 'reactstrap';

import { Modal } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { AllJobSetup } from "../../redux/actions/cyclic-job";
import authHeader from "../../redux/services/auth-header";
import { appUrl } from '../../redux/services/base';
import Jobs from './jobs-modal';

let timer = null;

const JobSetupList = (props) => {
    const history = useHistory();
    const [openJobsModal, setOpenJobsModal] = useState(false);
    const [jobSetupId, setJobSetupId] = useState(0);
    useEffect(() => {
        props.dispatch(AllJobSetup("", null))
    }, []);



    const previous = () => {
        props.dispatch(AllJobSetup(props?.job_setup_list.previous, null));
        console.log(props?.job_setup_list.previous);
    };

    const next = () => {
        props.dispatch(AllJobSetup(props?.job_setup_list.next, null));
        console.log(props?.job_setup_list.next);
    };



    const search = (event) => {
        if (timer != null) {
            window.clearTimeout(timer);
        }
        timer = window.setTimeout(function () {
            let search = `${event.target.value}`;
            if (search != "") {
                props.dispatch(AllJobSetup("", search));
            }
            else {
                props.dispatch(AllJobSetup("", "clear"));
            }
        }, 500);
    };


    const changeRoute = (e, id) => {
        e.preventDefault();
        history.push(`/jobs/${id}`)
    }

    const exportAsCSV = async (url, jobId) => {
        return await axios.get(url, { headers: authHeader(), responseType: 'blob' }).then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            if (jobId == null || jobId == undefined) {
                link.setAttribute('download', `pending_jobs_${new Date().toLocaleDateString()}.csv`);
            } else {
                link.setAttribute('download', `job_${jobId}.csv`);
            }
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }


    return (
        <div>

            <Modal
                width="95%"
                open={openJobsModal}
                // visible={openJobsModal}
                title={<h4>Jobs</h4>}
                footer={null}
                onCancel={() => {
                    setJobSetupId(-1);
                    setOpenJobsModal(false);
                }}
                afterClose={() => {

                }}
            >
                <Jobs setupId={jobSetupId} jobType="" />
            </Modal>

            <Button type="primary" onClick={() => {
                exportAsCSV(`${appUrl}/cyclic/job/api/jobs?export=1&allOpen=1`);
            }} style={{ position: 'absolute', top: -60, right: 0 }}>Export Pending/Open Jobs As CSV</Button>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span></span>
                <input type="text" placeholder="Search..." onKeyUp={search} />
            </div>
            <Table responsive striped hover className="tbl-small">
                <thead>
                    <tr>
                        <th>Job Title</th>
                        <th>Category</th>
                        <th>Area</th>
                        <th>Department</th>
                        <th>Item Measured</th>
                        <th>Equipment</th>
                        <th>Shift</th>
                        <th>Last Completed Date</th>
                        <th>Export</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props?.job_setup_list?.results?.map((job, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <b style={{ color: '#1890ff', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                                            setJobSetupId(job.id);
                                            setOpenJobsModal(true);
                                        }}>{job.job_title}</b>
                                        {/* <a href="#" onClick={(e) => changeRoute(e, job.id)} >{job.job_title}</a> */}
                                    </td>
                                    <td>{job.category}</td>
                                    <td>{job.area}</td>
                                    <td>{job.location?.name}</td>
                                    <td>{job.item_measured}</td>
                                    <td>{job.equipment}</td>
                                    <td>{job.shift}</td>
                                    <td>{job.last_job_completed_date ? moment(job.last_job_completed_date).format('lll') : "-"}</td>
                                    <td>
                                        <Button type="primary" onClick={() => { exportAsCSV(`${appUrl}/cyclic/job/api/jobs?export=1&setupId=${job.id}`, job.id); }}>Export As CSV</Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            {/* </CardText> */}
            <div className="text-right">
                <Button disabled={props?.job_setup_list?.previous == null} onClick={previous}>Prev</Button>
                &nbsp;&nbsp;
                <Button disabled={props?.job_setup_list?.next == null} onClick={next}>Next</Button>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { job_setup_list } = state.cyclicJob;
    const { auth } = state;
    const { token } = auth;
    return {
        job_setup_list,
        token
    }
}

export default connect(mapStateToProps)(JobSetupList);