import SPCService from "../services/spc";
import {
  SET_MESSAGE,
  SPC
} from "../types";


export const AllSPC = (url, query) => (dispatch) => {
  return SPCService.allSPC(url, query).then(
    (data) => {
      dispatch({
        type: SPC,
        payload: data
      });
      return Promise.resolve()
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, status: error.response.status },
      });

      return Promise.reject();
    }
  );
};

export const FetchSPC = (spcId) => (dispatch) => {
  return SPCService.fetchSPC(spcId).then(
    (data) => {
      return data?.results;
    },
    (error) => {
      return Promise.reject();
    }
  );
};



export const GetLineNumber = (part_number) => (dispatch) => {
  return SPCService.allSPCLines(part_number).then(
    (data) => {
      return data?.results;
    },
    (error) => {
      return Promise.reject();
    }
  );
};

export const GetDepartments = () => (dispatch) => {
  return SPCService.allDepartments().then(
    (data) => {
      return data?.results;
    },
    (error) => {
      return Promise.reject();
    }
  );
};

