import { Button, Checkbox, Input, Modal } from 'antd';
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    Table
} from 'reactstrap';
import authHeader from "../../redux/services/auth-header";
import { cyclicJobUrl } from "../../redux/services/base";


import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { AllJobs } from "../../redux/actions/cyclic-job";
const { TextArea } = Input;

let timer = null;


const Jobs = (props) => {
    const { jobSetupId } = useParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [currentJob, setCurrentJob] = useState();


    const [file, setFile] = useState();
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [hoursSpent, setHoursSpent] = useState(0);
    const [missingComponent, setMissingComponent] = useState(false);
    const [externalLink, setExternalLink] = useState('');



    const loadJobs = (query) => {

        let payload = {
            "setup_id": props?.setupId
        };
        if (props?.jobType != "") {
            payload["search"] = props?.jobType;
        }
        if (query != null && query != undefined && query != '') {
            payload["query"] = query
        }
        props.dispatch(AllJobs(payload))
    }

    useEffect(() => {
        loadJobs('');
    }, [props?.setupId]);

    const handleCommentChange = event => {
        setComment(event.target.value);
    }

    const handleMissingComponentChange = event => {
        setMissingComponent(event.target.checked);
    }

    const handleHoursSpentChange = event => {
        const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
        if (event.target.value.match(regex)[0])
            setHoursSpent(event.target.value);
    }

    const handleFileChange = event => {
        if (event.target.value != "") {
            setFile(event.target.files[0]);
        }
        else {
            setFile(null);
        }
    }

    const makeUrlsClickable = text => {
        // debugger;
        // Regular expression to find URLs in the text
        const urlRegex = /((https?:\/\/)?(www\.)?[\w\.-]+\.[a-zA-Z]{2,}(\/\S*)?)/g;

        // Replace URLs with clickable links
        let data = text?.replace(urlRegex, (url) => {
            console.log('url > ', url);

            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                url = 'http://' + url;
            }

            // Construct the HTML anchor tag with the URL
            return `<br/><a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a><br/>`;
        });
        // console.log('data > ', data);
        return data;
    }

    const submit = () => {
        if (file == null && comment == '') {
            return;
        }

        let formData = new FormData();
        formData.append("job_number", currentJob.job_number);
        formData.append("comments", comment);
        formData.append("external_links", externalLink);
        formData.append("hours_spent", hoursSpent);
        formData.append("missing_component", missingComponent);

        if (file != null) {
            formData.append("completion_evidence", file);
        }
        setLoading(true);

        axios.post(`${cyclicJobUrl}/job/submit/`, formData, { headers: authHeader(), 'Content-Type': 'multipart/form-data' }).then((response) => {
            setLoading(false);
            setModalOpen(false);
            loadJobs('');
        }).catch(err => {
            setLoading(false);
            alert('Something went wrong!');
        });
    }

    const pickJob = () => {
        const payload = { "job_number": currentJob.job_number };

        setLoading(true);
        // String url = "$_baseUrl/cyclic/job/api/job/$jobId/";

        axios.patch(`${cyclicJobUrl}/job/${currentJob.job_number}/`, payload, { headers: authHeader() }).then((response) => {
            setLoading(false);
            setModalOpen(false);
            loadJobs();
        }).catch(err => {
            setLoading(false);
            alert('Something went wrong!');
        });
    }

    const previous = () => {
        props.dispatch(AllJobs({}, props?.jobs.previous));
    };

    const next = () => {
        props.dispatch(AllJobs({}, props?.jobs.next));
    };


    const search = (event) => {
        if (timer != null) {
            window.clearTimeout(timer);
        }
        timer = window.setTimeout(function () {
            let search = `${event.target.value}`;
            if (search != "") {
                loadJobs(search);
                // props.dispatch(AllJobSetup("", search));
            }
            else {
                loadJobs('');
                // props.dispatch(AllJobSetup("", "clear"));
            }
        }, 500);
    };


    return (
        <div>
            <Modal
                title={currentJob?.job_title}
                centered={true}
                visible={modalOpen}
                onOk={() => setModalOpen(false)}
                onCancel={() => setModalOpen(false)}
                destroyOnClose={true}
                width={'70%'}
                footer={[
                    currentJob?.job_status == 'Pending' && currentJob?.responsible_person ? (
                        <Button key="submit" type="primary" loading={loading} onClick={submit}>
                            Mark as Completed
                        </Button>
                    ) : currentJob?.responsible_person == null ? (
                        <Button key="back" onClick={pickJob}>
                            Pick This Job
                        </Button>
                    ) : (
                        < Button key="back" onClick={() => setModalOpen(false)}>
                            Return
                        </Button>
                    )
                ]}
            >
                {
                    currentJob ? (
                        <div>
                            <table className="table table-bordered">
                                <tr>
                                    <th>
                                        <p>Area</p>
                                    </th>
                                    <td>
                                        <p>{currentJob?.job_setup.area}</p>
                                    </td>

                                    <th><p>Department</p></th>
                                    <td>
                                        <p>{currentJob?.job_setup?.location?.name}</p>
                                    </td>

                                    <th><p>Item Measured</p></th>
                                    <td>
                                        <p>{currentJob?.job_setup.item_measured}</p>
                                    </td>
                                </tr>

                                <tr>
                                    <th><p>Equipment</p></th>
                                    <td colSpan={2}>
                                        <p>{currentJob?.job_setup.equipment}</p>
                                    </td>

                                    <th><p>Equipment Verification Method</p></th>
                                    <td colSpan={2}>
                                        <p>{currentJob?.job_setup.equipment_verification_method}</p>
                                    </td>
                                </tr>

                                <tr>
                                    <th><p>Frequency</p></th>
                                    <td colSpan={2}>
                                        <p>{currentJob?.job_setup.frequency}</p>
                                    </td>

                                    <th><p>Job Status</p></th>
                                    <td colSpan={2}>
                                        <p>{currentJob.job_status}</p>
                                    </td>
                                </tr>

                                <tr>
                                    <th><p>Job Open Date</p></th>
                                    <td>
                                        <p>{currentJob?.job_date ? new Date(currentJob?.job_date).toLocaleDateString() : "-"}</p>
                                    </td>

                                    <th><p>Job Due Date</p></th>
                                    <td>
                                        <p>{currentJob?.due_date ? new Date(currentJob?.due_date).toLocaleDateString() : "-"}</p>
                                    </td>

                                    <th><p>Completed Date</p></th>
                                    <td>
                                        <p>{currentJob.completed_date ? new Date(currentJob.completed_date).toLocaleDateString() : "-"}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th><p>Scheduling System</p></th>
                                    <td>
                                        <p>{currentJob?.job_setup.scheduling_system}</p>
                                    </td>
                                    <th><p>Responsible Person</p></th>
                                    <td>
                                        {currentJob?.responsible_person?.first_name} {currentJob?.responsible_person?.last_name}
                                    </td>

                                    <th><p>Category</p></th>
                                    <td >
                                        {currentJob?.job_setup?.category}
                                    </td>
                                </tr>
                            </table>


                            <b>Guideline Requirement</b>
                            <p>{currentJob?.job_setup.guideline_requirement}</p>

                            <b>Shift</b>
                            <p>{currentJob?.job_setup.shift}</p>

                            <b>Job Description</b>
                            <p>{currentJob?.job_setup?.job_description}</p>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <b>Hours Spent</b>
                                    <p>
                                        {
                                            currentJob?.job_status == 'Pending' && currentJob?.responsible_person ? (
                                                <Input onChange={handleHoursSpentChange} style={{ width: 150 }} value={hoursSpent} type="number" />
                                            ) : currentJob?.hours_spent
                                        }
                                    </p>
                                </div>
                                <div style={{ marginLeft: 30 }}>
                                    <b>&nbsp;</b>
                                    <p>
                                        {
                                            currentJob?.job_status == 'Pending' && currentJob?.responsible_person ? (

                                                <label>
                                                    <Checkbox onChange={handleMissingComponentChange} value={missingComponent} />
                                                    &nbsp;&nbsp;<b>Equipement Missing</b>
                                                </label>


                                            ) : <><b>Equipement Missing: &nbsp;&nbsp;</b>{currentJob?.missing_component == true ? "Yes" : "No"}</>
                                        }
                                    </p>
                                </div>

                            </div>


                            <b>
                                External Links
                            </b>
                            <p>
                                {
                                    currentJob?.job_status == 'Pending' && currentJob?.responsible_person ? (
                                        <Input style={{ width: '250px' }} onChange={(e) => setExternalLink(e.target.value)} />

                                    ) : <a href={currentJob?.external_links} target='_blank'>{currentJob?.external_links}</a>
                                }

                            </p>



                            <b>Job Comment</b>
                            <p>
                                {
                                    currentJob?.job_status == 'Pending' && currentJob?.responsible_person ? (
                                        <TextArea
                                            onChange={handleCommentChange}
                                            placeholder="Enter your comments here..."
                                            autoSize={{
                                                minRows: 2,
                                                maxRows: 4,
                                            }}
                                        />
                                    ) : <p dangerouslySetInnerHTML={{ __html: makeUrlsClickable(currentJob?.comments) }}></p>

                                }
                            </p>

                            {
                                currentJob?.job_status == 'Pending' && currentJob?.responsible_person ? (
                                    <div>
                                        <b>Attach Completion Evidence &nbsp;&nbsp;</b>
                                        <input type="file" onChange={handleFileChange} />
                                    </div>
                                ) : (
                                    <div>
                                        {
                                            currentJob.completion_evidence ? (<a href={currentJob.completion_evidence} target="_blank">Download Completion Evidence</a>) : null
                                        }
                                    </div>
                                )
                            }

                        </div >
                    ) : null
                }
            </Modal >



            <input style={{ position: 'absolute', top: 15, right: 60 }} type="text" placeholder="Search..." onKeyUp={search} />

            <Table responsive striped hover className="tbl-small">
                <thead>
                    <tr>
                        <th>Job Title</th>

                        <th>Hours Required</th>
                        {
                            props?.jobType == "" ? (
                                <>
                                    <th>Actual Hours</th>
                                    <th>Job Cost</th>
                                </>
                            ) : null
                        }

                        <th>Category</th>
                        <th>Equipment</th>
                        <th>Item Measured</th>
                        <th>Department</th>
                        <th>Area</th>
                        <th>Shift</th>
                        <th>Owner</th>

                        {
                            props?.jobType != "" ? (
                                <th>Job Open Date</th>
                            ) : null
                        }
                        {
                            props?.jobType == "" ? (
                                <th>Pickup Date</th>
                            ) : null
                        }

                        <th>Job Due Date</th>
                        {
                            props?.jobType == "" ? (
                                <th>Completed Date</th>
                            ) : null
                        }
                        {
                            props?.jobType == "" ? (
                                <th>Responsible Person</th>
                            ) : null
                        }

                        {/* <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        props?.jobs?.results?.map((job, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: 100, whiteSpace: 'nowrap' }}>
                                            <a href="#" onClick={() => {
                                                setModalOpen(true); setCurrentJob(job);
                                            }}>{job.job_title}</a>
                                        </div>
                                    </td>


                                    <td>{job?.job_setup?.estimated_hours}</td>

                                    {
                                        props?.jobType == "" ? (
                                            <>
                                                <td>{job.hours_spent}</td>
                                                <td>
                                                    {job.missing_component ? job.job_setup.equipment_cost : "-"}
                                                </td>
                                            </>
                                        ) : null
                                    }

                                    <td>{job?.job_setup?.category}</td>
                                    <td>{job?.job_setup?.equipment}</td>
                                    <td>{job?.job_setup?.item_measured}</td>
                                    <td>{job?.job_setup?.location?.name}</td>
                                    <td>{job?.job_setup?.area}</td>
                                    <td>{job?.job_setup?.shift}</td>
                                    <td>{job?.job_setup?.owner.first_name} {' '} {job?.job_setup?.owner.last_name}</td>

                                    {
                                        props?.jobType != "" ? (
                                            <td>
                                                {job.job_date ? moment(new Date(job.job_date)).format('lll') : "-"}
                                            </td>
                                        ) : null
                                    }
                                    {
                                        props?.jobType == "" ? (

                                            <td>
                                                {job.picked_date ? moment(new Date(job.picked_date)).format('lll') : "-"}
                                            </td>
                                        ) : null
                                    }

                                    <td>
                                        {

                                            job.due_date ? moment(new Date(job.due_date)).format('lll') : "-"
                                        }
                                    </td>

                                    {
                                        props?.jobType == "" ? (
                                            <td>
                                                {

                                                    job.completed_date ? moment(new Date(job.completed_date)).format('lll') : "-"
                                                }
                                            </td>
                                        ) : null
                                    }

                                    {
                                        props?.jobType == "" ? (
                                            <td>{job?.responsible_person?.first_name} {' '}{job?.responsible_person?.last_name}</td>
                                        ) : null
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>

            <div className="text-right">
                <Button disabled={props?.jobs?.previous == null} onClick={previous}>Prev</Button>
                &nbsp;&nbsp;
                <Button disabled={props?.jobs?.next == null} onClick={next}>Next</Button>
            </div>

        </div >
    )
}

function mapStateToProps(state) {
    const { jobs } = state.cyclicJob;
    const { auth } = state;
    const { token } = auth;
    return {
        jobs,
        token
    }
}

export default connect(mapStateToProps)(Jobs);