import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";

import BoardsV2 from "../../components/boards/index-v2";
import { AllCategories, AllCoOwners, AllMembers, AllPriority, GetFlags } from "../../redux/actions/boards";



import { Layout, Menu } from 'antd';
const { Header, Content, Sider } = Layout;




const BoardsV2Page = (props) => {
    const [category, setCategory] = useState(null);

    useEffect(() => {
        props.dispatch(AllCategories());
        props.dispatch(AllPriority());
        props.dispatch(GetFlags());
        props.dispatch(AllMembers());
        props.dispatch(AllCoOwners());
    }, []);

    const onMenuClick = ({ key }) => {
        setCategory(key);
    }


    return (
        <Container fluid={true} className="SUMAN_CONTAINER" style={{ paddingLeft: 0, paddingRight: 0, height: 'calc(100% - 1px)', border: '1px solid red' }}>
            <Layout className="SUMAN_LAYOUT" style={{ marginTop: 0, height: 'inherit' }}>
                <Sider width={250} className="site-layout-background1" style={{
                    overflow: 'auto',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}>
                    <Menu
                        theme="dark"
                        mode="inline"
                        onClick={onMenuClick}
                        style={{
                            height: '100%',
                            borderRight: 0,
                        }}>
                        {
                            props?.categories?.map(_category => (
                                <Menu.Item key={_category.name}>{_category.name}</Menu.Item>
                            ))
                        }

                        <Menu.Item>Templates</Menu.Item>
                    </Menu>
                </Sider>
                <Layout
                    style={{
                        padding: '0 24px 24px',
                        marginLeft: 220
                    }}
                >

                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        <BoardsV2 category={category} />
                    </Content>
                </Layout>
            </Layout>
        </Container >
    );
};

function mapStateToProps(state) {
    const { categories } = state.boards;
    return {
        categories
    }
}
export default connect(mapStateToProps)(BoardsV2Page);

// export default connect(null, null)(BoardsV2Page);
