
import { useEffect, useState } from "react";
import AddCommand from "../AddCommand";

const commandColor = '#ff7675';

// Has params: props.id, props.comps, props.setComps, props.indices, props.setIsScriptRendered, props.childrenArray
export default function QLCharLengthOperatorCommand(props) {

    const [evaluatedErrors, setEvaluatedErrors] = useState(false);
    const [color, setColor] = useState(commandColor);

    const [isAddingCommand, setIsAddingCommand] = useState(false);

    if (!evaluatedErrors) {
        if (props.childrenArray[0].length === 1) {
            setColor(commandColor);
            setEvaluatedErrors(true);
        } else {
            setColor('#FC427B');
            setEvaluatedErrors(true);
        }
    }

    useEffect(() => {
        if (props.childrenArray[0].length === 1) {
            setColor(commandColor);
            setEvaluatedErrors(true);
        } else {
            setColor('#FC427B');
            setEvaluatedErrors(true);
        }
    });

    let comment = -1;
    let whitespace = 'count';
    let thisObj = props.comps[props.id].script[props.indices[0]];
    for (let k = 0; k < props.indices.length - 1; ++k) {
        thisObj = thisObj.children[props.indices[k + 1]];
    }

    try {
        comment = thisObj.comment;
        if (thisObj.hasOwnProperty('whitespace')) {
            whitespace = thisObj.whitespace;
        }
    } catch (e) {

    }

    return <>
        <div style={{
            marginBottom: '2vmax',
            overflow: 'auto',
            display: 'inline-block',
            marginRight: '4vmax',
            verticalAlign: 'top'
        }}>
            <div style={{
                padding: '1vmax',
                backgroundColor: color,
                color: '#000',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '500'
            }}>
                QUANT
            </div>
            <a className='panel-button' style={{
                padding: '1vmax',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '300',
                border: '0pt',
                borderLeft: `1pt solid #faf3dd`,
                marginLeft: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                let new_comps = [...props.comps];
                let thisObj = new_comps[props.id].script[props.indices[0]];
                for (let k = 0; k < props.indices.length - 2; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                } thisObj.children.splice(props.indices[props.indices.length - 1], 1);
                props.setComps(new_comps);
                props.setIsScriptRendered(false);
            }}>
                Delete
            </a>
            <i className='fa-solid fa-comment-dots pointy comp' style={{
                padding: '1vmax',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontWeight: '300',
                border: '0pt',
                borderLeft: `1pt solid #faf3dd`,
                marginLeft: '1vmax',
                backgroundColor: comment === -1 ? "#000" : "#faf3dd",
                color: comment === -1 ? "#faf3dd" : "#000"
            }} onClick={(e) => {
                e.preventDefault();
                let new_comps = [...props.comps];
                let thisObj = new_comps[props.id].script[props.indices[0]];
                for (let k = 0; k < props.indices.length - 1; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                }
                if (thisObj.comment !== -1) {
                    thisObj.comment = -1;
                } else {
                    thisObj.comment = "Comments";
                }
                props.setComps(new_comps);
                props.setIsScriptRendered(false);
            }}></i>

            {/* Copy Command Icon */}
            <i className='fa-solid fa-copy pointy comp panel-button' style={{
                padding: '1vmax',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontWeight: '300',
                border: '0pt',
                borderLeft: `1pt solid #faf3dd`,
                marginLeft: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                let thisObj = props.comps[props.id].script[props.indices[0]];
                for (let k = 0; k < props.indices.length - 1; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                }
                navigator.clipboard.writeText(JSON.stringify(thisObj));
            }}></i>

            <div style={{
                padding: '2vmax 0',
                borderLeft: `3pt solid ${color}`,
                borderBottom: `1pt dashed ${color}`,
                paddingLeft: '2vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '500',
                paddingRight: '2vmax'
            }} className='code-block'>
                {props.childrenArray[0].length === 0 ? <i className='fa-solid fa-plus panel-icon' style={{
                    fontSize: '1vmax',
                    padding: '0.5vmax',
                    borderRadius: '100px',
                    marginBottom: '1.5vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setIsAddingCommand('0');
                }} /> : props.childrenArray[0]}
                <br />
                <div style={{
                    display: 'inline-block',
                    fontSize: '1.15vmax',
                    marginTop: '0vmax'
                }}>
                    NUMBER OF CHARACTERS <select className='panel-dropdown' onChange={(e) => {
                        e.preventDefault();
                        let new_comps = [...props.comps];
                        let thisObj = new_comps[props.id].script[props.indices[0]];
                        for (let k = 0; k < props.indices.length - 1; ++k) {
                            thisObj = thisObj.children[props.indices[k + 1]];
                        } thisObj.whitespace = e.target.value;
                        props.setComps(new_comps);
                        props.setIsScriptRendered(false);
                    }} value={whitespace} style={{
                        marginLeft: '0.5vmax',
                        marginRight: '0.5vmax',
                        maxWidth: '8vmax'
                    }}>
                        <option value="count">Counting</option>
                        <option value="nocount">Not Counting</option>
                    </select> WHITESPACE</div>
                {
                    comment === -1 ? <></> : <>
                        <br />
                        <input className='panel-input-reverse' style={{
                            width: '100%',
                            height: '2vmax',
                            marginTop: '1vmax'
                        }} value={comment} onChange={(e) => {
                            let new_comps = [...props.comps];
                            let thisObj = new_comps[props.id].script[props.indices[0]];
                            for (let k = 0; k < props.indices.length - 1; ++k) {
                                thisObj = thisObj.children[props.indices[k + 1]];
                            }
                            thisObj.comment = e.target.value;
                            props.setComps(new_comps);
                            props.setIsScriptRendered(false);
                        }}></input>
                    </>
                }
            </div>
        </div>
        {
            isAddingCommand === '0' ? <AddCommand id={props.id} comps={props.comps}
                setComps={props.setComps} indices={props.indices.concat([0])}
                setIsScriptRendered={props.setIsScriptRendered}
                setIsAddingCommand={setIsAddingCommand}
                availableCommands={[
                    props.comps[props.id].type !== "intermediate/InputTable" ? "variable/get" : "variable/table/get",
                    props.comps[props.id].type !== "intermediate/InputTable" ? "self/value" : "self/table/value"
                ]} /> : <></>
        }
    </>;

}