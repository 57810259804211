
import {
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

// import NavBar from '../../../views/utils/NavBar';
import ControlPanel from './utils-components/ControlPanel';
import FormView from './utils-components/FormView';

// components
import Box from './primitive/Box';
import Button from './primitive/Button';
import Check from './primitive/Check';
import Drop from './primitive/Drop';
import Image from './primitive/Image';
import Input from './primitive/Input';
import Label from './primitive/Label';
import List from './primitive/List';
import Radio from './primitive/Radio';

import InputTable from './intermediate/InputTable';
import Signature from './intermediate/Signature';
import Snapshot from './intermediate/Snapshot';

// import Header from '../../../views/utils/Header';
import FormService from '../../redux/services/forms';
import Modal from './utils/Modal';

import { message } from "antd";

import { getHeight, whichLayoutForPages } from './misc/layouts';

const info = () => {
    message.info('Use CTRL/Command+Enter to save the changes of the form.', 5);
};


// export default function CreateForm() {
const CreateForm = (props) => {
    let size_of_grid = window.innerWidth * 0.00001;
    const location = useLocation();
    const [messageApi, contextHolder] = message.useMessage();
    const [saveStatus, setSaveStatus] = useState(0); // -1 = error, 0 = nothing, 1 = success
    const [loaded, setLoaded] = useState(false);
    const [dbId, setDbId] = useState('');

    const [name, setName] = useState('My Form');


    // Array of components to be rendered
    const [components, setComponents] = useState([]);

    // ID in component array of currently selected/hovered element
    const [selectedEl, setSelectedEl] = useState(-1);
    const [contextMenu, setContextMenu] = useState([false, -1, [0, 0]]); // isContextMenuShowing?, id of active el, coordinates
    const [hoveredEl, setHoveredEl] = useState(-1);
    const message_key = 'updatable';

    const [magneticGrid, setMagneticGrid] = useState(false);
    const [magneticGridPartitions, setMagneticGridPartitions] = useState(100);

    // possible layouts: None, A3, A4
    const [layout, setLayout] = useState("A4");

    const saveForm = () => {
        let form_obj = {
            name: name,
            content: components,
            pages: pages,
            vars: vars,
            externals: externals,
            parent: parent
        };
        console.log('form_obj > ', form_obj);
        const formId = document.getElementById('formId').textContent;
        if (formId !== '') {
            messageApi.open({
                key: message_key,
                type: 'loading',
                content: 'Saving...',
            });

            FormService.updateForm(formId, form_obj).then(resp => {
                getFormPages();
                messageApi.open({
                    key: message_key,
                    type: 'success',
                    content: 'Your form was successfully saved.',
                });
            }).catch(err => {
                messageApi.open({
                    key: message_key,
                    type: 'error',
                    content: 'Your form could not be saved.',
                });
            });
        }
    }


    const saveButton = useRef();
    // handle what happens on key press
    const handleKeyPress = useCallback((event) => {

        if ((event.ctrlKey === true || event.metaKey === true) && event.key == 'Enter') {
            saveButton.current.click();
        }
    }, []);


    useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);


    // States for pages
    const [pages, setPages] = useState([
        {
            height: getHeight('A4', 0.7),
            visibleTo: 'all',
            editableTo: 'all'
        }
    ]);


    const [currentPage, setCurrentPage] = useState(0);

    const [vars, setVars] = useState([]);
    const [externals, setExternals] = useState({
        flags: []
    });

    const [parent, setParent] = useState('');

    const getFormPages = () => {
        return FormService.getFormPages(location.state.form_id).then(res => {
            const _pages = []
            const _contents = [];
            res.data.map(item => {
                item["page"]["page_id"] = item.page_id;
                _pages.push(item["page"])
                item.content.map(content => {
                    content["page_id"] = item.page_id;
                    _contents.push(content);
                });
            });

            setComponents(_contents);
            setPages(_pages);

            return _pages;
        });
    }

    useEffect(() => {
        if (location.state != null && location.state != undefined) {
            info();
            FormService.getFormDetail(location.state.form_id).then(res => {
                console.log('Res ->', res);
                setName(res.name);
                setVars(res.vars);
                setExternals(res.externals);
                setParent(res.parent);
                setDbId(res.form_id);
                document.getElementById('formId').textContent = res.form_id;
                let _pages = getFormPages();
                _pages.then((e) => {
                    setLayout(whichLayoutForPages(e));
                });
            });
        }

    }, []);


    // Captures width and height of FormView, and updates components
    const dimRef = useRef(null);

    let components_render = [];
    for (let k = 0; k < components.length; ++k) {
        if (components[k].page === currentPage) {
            if (components[k].type === 'primitive/Label') {
                components_render.push(
                    <Label id={k} comps={components} setter={setComponents}
                        vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                        sel={selectedEl} setSel={setSelectedEl}
                        hov={hoveredEl} setHov={setHoveredEl}
                        vars={vars} setVars={setVars}
                        contextMenu={contextMenu} setContextMenu={setContextMenu}
                        magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                        magneticGridPartitions={magneticGridPartitions} />
                );
            } else if (components[k].type === 'primitive/Box') {
                components_render.push(
                    <Box id={k} comps={components} setter={setComponents}
                        vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                        sel={selectedEl} setSel={setSelectedEl}
                        hov={hoveredEl} setHov={setHoveredEl}
                        vars={vars} setVars={setVars}
                        contextMenu={contextMenu} setContextMenu={setContextMenu}
                        magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                        magneticGridPartitions={magneticGridPartitions} />
                );
            } else if (components[k].type === 'primitive/Input') {
                components_render.push(<Input id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu}
                    magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                    magneticGridPartitions={magneticGridPartitions} />
                );
            } else if (components[k].type === 'primitive/Button') {
                components_render.push(<Button id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu}
                    magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                    magneticGridPartitions={magneticGridPartitions} />
                );
            } else if (components[k].type === 'primitive/Radio') {
                components_render.push(<Radio id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu}
                    magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                    magneticGridPartitions={magneticGridPartitions} />
                );
            } else if (components[k].type === 'primitive/Check') {
                components_render.push(<Check id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu}
                    magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                    magneticGridPartitions={magneticGridPartitions} />
                );
            } else if (components[k].type === 'primitive/List') {
                components_render.push(<List id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu}
                    magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                    magneticGridPartitions={magneticGridPartitions} />
                );
            } else if (components[k].type === 'primitive/Drop') {
                components_render.push(<Drop id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu}
                    magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                    magneticGridPartitions={magneticGridPartitions} />
                );
            } else if (components[k].type === 'primitive/Image') {
                components_render.push(
                    <Image id={k} comps={components} setter={setComponents}
                        vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                        sel={selectedEl} setSel={setSelectedEl}
                        hov={hoveredEl} setHov={setHoveredEl}
                        vars={vars} setVars={setVars}
                        contextMenu={contextMenu} setContextMenu={setContextMenu}
                        magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                        magneticGridPartitions={magneticGridPartitions} />
                );
            } else if (components[k].type === 'intermediate/Signature') {
                components_render.push(<Signature id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu}
                    magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                    magneticGridPartitions={magneticGridPartitions} />
                );
            } else if (components[k].type === 'intermediate/InputTable') {
                components_render.push(
                    <InputTable id={k} comps={components} setter={setComponents}
                        vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                        sel={selectedEl} setSel={setSelectedEl}
                        hov={hoveredEl} setHov={setHoveredEl}
                        vars={vars} setVars={setVars}
                        contextMenu={contextMenu} setContextMenu={setContextMenu}
                        magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                        magneticGridPartitions={magneticGridPartitions} />
                );
            }
            else if (components[k].type === 'intermediate/Snapshot') {
                components_render.push(
                    <Snapshot id={k} comps={components} setter={setComponents}
                        vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                        sel={selectedEl} setSel={setSelectedEl}
                        hov={hoveredEl} setHov={setHoveredEl}
                        vars={vars} setVars={setVars}
                        contextMenu={contextMenu} setContextMenu={setContextMenu}
                        magneticGrid={magneticGrid} setMagneticGrid={setMagneticGrid}
                        magneticGridPartitions={magneticGridPartitions} />
                );
            }
        }

    }

    return (<>
        {contextHolder}
        <div style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100vh',
            backgroundColor: '#fff',
            overflow: 'auto'
        }} id="wrapper" onScroll={(e) => {
            setContextMenu([false, -1, [0, 0]]);
        }}>

            <div className="create-form-container">

                <button style={{ display: 'none' }} ref={saveButton} onClick={saveForm}>Save</button>
                <ControlPanel comps={components} setComps={setComponents} sel={selectedEl}
                    setSel={setSelectedEl} hov={hoveredEl} setHov={setHoveredEl}
                    pages={pages} setPages={setPages}
                    currentPage={currentPage} setCurrentPage={setCurrentPage} vars={vars} setVars={setVars}
                    externals={externals} setExternals={setExternals} parent={parent} setParent={setParent}
                    onFormSaveCallback={getFormPages}
                    name={name} setName={setName} dbId={dbId} setDbId={setDbId} pageId={pages[currentPage].page_id}
                    layout={layout} setLayout={setLayout} />

                {
                    (pages != null && pages.length > 0) ? (
                        <FormView reference={dimRef} vars={vars}
                            externals={externals}
                            parent={parent}
                            formName={name}
                            onFormSave={saveForm}
                            user={props?.user} formId={location?.state?.form_id} comps={components} setComps={setComponents}
                            content={components_render} position={{
                                x: '1.5vmax',
                                y: '87vh - ((100vh - (4vmax + 12vh) - 5vh) - 5vw + 3.8vw + 1pt + 1.5vmax)'
                            }} size={{
                                w: '100vw - 3vmax - 20vw - 4pt - 1.5vmax'
                            }} isEditable={true} pages={pages} setPages={setPages}
                            currentPage={currentPage} setCurrentPage={setCurrentPage}
                            setSelectedEl={setSelectedEl} setContextMenu={setContextMenu}
                            css={{
                                zIndex: '0'
                            }} magneticGrid={magneticGrid}
                            setMagneticGrid={setMagneticGrid}
                            magneticGridPartitions={magneticGridPartitions}
                            setMagneticGridPartitions={setMagneticGridPartitions}
                            layout={layout} setLayout={setLayout} />
                    ) : null
                }
                {
                    contextMenu[0] ? <div style={{
                        width: 150,
                        position: 'absolute',
                        top: `${document.getElementById("wrapper").scrollTop + contextMenu[2][1]}px`,
                        left: `${contextMenu[2][0]}px`,
                        zIndex: '100',
                        background: '#000',
                        color: '#faf3dd',
                        border: '#faf3dd 1pt solid'
                    }}>
                        <div className='pointy dark-to-light-on-hover' style={{
                            borderBottom: '#faf3dd 0.75pt solid',
                            padding: '0.5vmax',
                            fontWeight: '400',
                            fontSize: '1vmax'
                        }} onClick={(e) => {
                            e.stopPropagation();
                            setContextMenu([false, -1, [0, 0]]);
                        }}>Close</div>
                        <div className='pointy dark-to-light-on-hover' style={{
                            padding: '0.5vmax',
                            fontWeight: '400',
                            fontSize: '1vmax',
                            borderBottom: '#faf3dd 0.75pt solid'
                        }} onClick={(e) => {
                            e.stopPropagation();
                            let new_comps = [...components];
                            new_comps.push(JSON.parse(JSON.stringify(new_comps[contextMenu[1]])));
                            setComponents(new_comps);
                            setContextMenu([false, -1, [0, 0]]);
                        }}>Duplicate</div>
                        <div className='pointy dark-to-light-on-hover' style={{
                            padding: '0.5vmax',
                            fontWeight: '400',
                            fontSize: '1vmax',
                            borderBottom: '#faf3dd 0.75pt solid'
                        }} onClick={(e) => {
                            e.stopPropagation();
                            setSelectedEl(-1);
                            if (pages.length > 1) {
                                let new_comps = [...components];
                                if (new_comps[contextMenu[1]].page + 1 === pages.length) {
                                    new_comps[contextMenu[1]].page = 0;
                                } else {
                                    new_comps[contextMenu[1]].page = new_comps[contextMenu[1]].page + 1;
                                }
                                setComponents(new_comps);
                            }
                            setContextMenu([false, -1, [0, 0]]);
                        }}>To Right</div>
                        <div className='pointy dark-to-light-on-hover' style={{
                            padding: '0.5vmax',
                            fontWeight: '400',
                            fontSize: '1vmax',
                            borderBottom: '#faf3dd 0.75pt solid'
                        }} onClick={(e) => {
                            e.stopPropagation();
                            setSelectedEl(-1);
                            if (pages.length > 1) {
                                let new_comps = [...components];
                                if (new_comps[contextMenu[1]].page === 0) {
                                    new_comps[contextMenu[1]].page = pages.length - 1;
                                } else {
                                    new_comps[contextMenu[1]].page = new_comps[contextMenu[1]].page - 1;
                                }
                                setComponents(new_comps);
                            }
                            setContextMenu([false, -1, [0, 0]]);
                        }}>To Left</div>
                        <div className='pointy dark-to-light-on-hover' style={{
                            padding: '0.5vmax',
                            fontWeight: '400',
                            fontSize: '1vmax'
                        }} onClick={(e) => {
                            e.stopPropagation();
                            setSelectedEl(-1);
                            let new_comps = [...components];
                            new_comps.splice(contextMenu[1], 1);
                            setComponents(new_comps);
                            setContextMenu([false, -1, [0, 0]]);
                        }}>Delete</div>
                    </div> : <></>
                }

            </div>



        </div>
        {saveStatus === 1 ? <Modal width='15' height='21.5'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '3vh',
                padding: '1vmax'
            }}>Your form was successfully saved.</p>
            <a className='panel-button' onClick={(e) => {
                e.preventDefault();
                setSaveStatus(0);
            }}>Ok</a>
        </Modal> : <></>}

        {saveStatus === -1 ? <Modal width='15' height='21.5'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '3vh',
                padding: '1vmax'
            }}>Your form could not be saved.</p>
            <a className='panel-button' onClick={(e) => {
                e.preventDefault();
                setSaveStatus(0);
            }}>Ok</a>
        </Modal> : <></>}
    </>);

}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user
    }
}

export default connect(mapStateToProps)(CreateForm);