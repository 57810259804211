import React from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import SPCList from "../../components/spc";


const SPC = (props) => {


	return (
		<Container fluid={true}>
			<SPCList />
		</Container>
	);
};

export default connect()(SPC);

