import axios from "axios";
import authHeader from "./auth-header";
import { spcUrl } from "./base";

class SPCService {
  queryStringToJSON(queryString) {
    if (queryString.indexOf('?') > -1) {
      queryString = queryString.split('?')[1];
    }
    var pairs = queryString.split('&');
    var result = {};
    pairs.forEach(function (pair) {
      pair = pair.split('=');
      if (pair[0] != null && pair[0] != "" && pair[0] != undefined) {
        result[pair[0]] = decodeURIComponent(pair[1] || '');
      }
    });
    return result;
  };

  allSPC(url, query) {
    let data = {};

    if (url == "" || url == null || url == undefined) {
      url = `${spcUrl}/spc`;
    }
    else {
      data = this.queryStringToJSON(url);
      let x = data.search;
      if (x != null && x != undefined) {
        x = x.replace(/\+/g, " ")
        data.search = x;
      }
      delete data[""];
    }
    if (query == "clear") {
      data["search"] = "";
    }
    else if (query != null && query != "clear") {
      data["search"] = query;
    }
    if (url.indexOf('?') > 0) {
      url = url.substr(0, url.indexOf('?'));
    }
    url = url + "?all=1&" + new URLSearchParams(data).toString();
    return axios
      .get(url,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  allSPCParts(query) {
    let data = {};

    let url = `${spcUrl}/spc/unique/parts`;
    if (query == "clear") {
      data["search"] = "";
    }
    else if (query != null && query != "clear") {
      data["search"] = query;
    }
    if (url.indexOf('?') > 0) {
      url = url.substr(0, url.indexOf('?'));
    }
    url = url + "?" + new URLSearchParams(data).toString();
    return axios
      .get(url,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  allSPCLines(query) {
    let data = {};

    let url = `${spcUrl}/spc/unique/parts`;
    if (query == "clear") {
      data["search"] = "";
    }
    else if (query != null && query != "clear") {
      data["search"] = query;
    }
    data["lines"] = 1;
    if (url.indexOf('?') > 0) {
      url = url.substr(0, url.indexOf('?'));
    }
    url = url + "?" + new URLSearchParams(data).toString();
    return axios
      .get(url,
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  allDepartments() {
    let url = `${spcUrl}/spc/departments`;

    return axios.get(url, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }


  fetchSPC(spcId) {
    let url = `${spcUrl}/spc/${spcId}`;

    return axios.get(url, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }
  fetchBatchId(spcId) {
    let url = `${spcUrl}/spc/batch_id?spc_id=${spcId}`;

    return axios.get(url, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  async addDataPoint(payload, spcType) {
    const response = await axios.post(
      `${spcUrl}/spc/data/${spcType}/?spc_id=${payload.spc_id}`,
      payload,
      { headers: authHeader() }
    );
    return response;
  }

  async deleteDataPoint(spcId) {
    const response = await axios.delete(
      `${spcUrl}/spc/data/xbar/?spc_id=${spcId}`,
      { headers: authHeader() }
    );
    return response;
  }
  async calculateLimit(spcId) {
    // String formattedDate = DateFormat('yyyy-MM-dd kk:mm:ss').format(now);
    // Map map = {"spc_id": spc.id, "timestamp": formattedDate};
    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ` +
      `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
    const payload = {
      "spc_id": spcId,
      "timestamp": formattedDate
    };
    const response = await axios.post(
      `${spcUrl}/spc/${spcId}/calculate/ucl/lcl`,
      payload,
      { headers: authHeader() }
    );
    return response;
  }


  // Future<String> deleteLastDataPoint(int? spcId) async {
  //   String? accessToken = GlobalData.token.access;
  //   String url = "$baseUrl/api/v1/spc/data/xbar/?spc_id=$spcId";
  //   var uri = Uri.parse(url);
  //   var response = await http.delete(uri, headers: {
  //     'Authorization': 'Bearer $accessToken',
  //     'DeviceId': GlobalData.deviceId
  //   });
  //   if (response.statusCode == 200) {
  //     return jsonDecode(response.body)['message'];
  //   }
  //   return jsonDecode(response.body)['message'];
  // }

}




export default new SPCService();