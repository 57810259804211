import { Modal, Spin, message } from 'antd';
import React, { useState } from "react";
import { connect } from 'react-redux';
// import {
//     Card, CardBody,
//     CardText,
//     CardTitle
// } from 'reactstrap';
import { Button, Dropdown, Form, Input, Space } from 'antd';
import moment from 'moment';

import SPCService from "../../redux/services/spc";
const key = "updatable"

const AddSPC = (props) => {
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [spc, setSPC] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [boardForm] = Form.useForm(null);
    const [boardFormData, setBoardFormData] = useState(null);

    const loadInitialValues = async () => {
        setLoading(true);
        let res = await SPCService.fetchSPC(props?.spcId);
        setSPC(res);
        res = await SPCService.fetchBatchId(props?.spcId);
        boardForm.setFieldValue("batch_id", res.batch_id);
        setLoading(false);
    }


    const validateSampleData = (input, min_value, max_value) => {
        const sample_in_inches = spc.sample_in_inches;

        // Split the input by commas
        const values = input.split(',');
        if (spc?.sample_size != values.length) {
            return { isValid: false, message: `Data points must be equals to sample size` };
        }
        let data = [];

        // Iterate through each value to validate
        for (let i = 0; i < values.length; i++) {
            const value = values[i].trim(); // Trim whitespace
            let numericValue = 0;

            if (sample_in_inches) {
                try {
                    numericValue = eval(value);
                }
                catch (error) {
                    return { isValid: false, message: `Invalid input value: "${value}"` };
                }
                // Parse the value as a number
                numericValue = parseFloat(numericValue);
            }
            else {
                // Check if value is a valid decimal or integer
                if (!/^\d+(\.\d+)?$/.test(value)) {
                    return { isValid: false, message: `Invalid decimal/integer value: "${value}"` };
                }
                // Parse the value as a number
                numericValue = parseFloat(value);
            }



            // Check if value is within the range of lcl and ucl
            if (numericValue < min_value || numericValue > max_value) {
                return { isValid: false, message: `The value "${value}" is out of acceptable range (${min_value}-${max_value}).` };
            }
            data.push(numericValue);
        }
        return { isValid: true, message: "All values are valid.", "data": data };
    }

    const handleOnOk = () => {
        setLoading(true);
        boardForm.validateFields().then((data) => {
            const validate = validateSampleData(data.sample_data, parseFloat(spc.min_value), parseFloat(spc.max_value))
            if (validate.isValid == false) {
                boardForm.setFields([
                    {
                        "name": "sample_data",
                        "errors": [validate.message]
                    }
                ]);
                setLoading(false);
            }
            else {
                let spcType = spc?.chart_type == 'X-MR' ? "imr" : "xbar";
                let payload = {
                    "spc_id": props?.spcId,
                    "batch_id": data.batch_id,
                    "notes": data.notes,
                    "data": spcType == 'imr' ? validate.data[0] : validate.data,
                    "timestamp": moment().format('YYYY-MM-DD HH:mm:ss')
                }
                setSubmitting(true);
                SPCService.addDataPoint(payload, spcType).then((res) => {
                    setSubmitting(false);
                    setLoading(false);
                    message.success({ content: "Data point added successfully.", key });
                    setModalVisible(false);
                    props?.onSuccess();
                }).catch((res) => {
                    setSubmitting(false);
                });
            }
        }).catch((info) => {
            setLoading(false);
            console.log('Validate Failed:', info);
        });

    }
    const clearData = () => {
        setModalVisible(false);
    }


    const items = [
        {
            label: <Button type='primary' onClick={() => { props?.onDelete() }} ghost danger style={{ width: '100%' }}>Delete</Button>,
            key: '1',
        },
        {
            label: <Button type='primary' onClick={() => { props?.onCalculateLimit() }} ghost style={{ width: '100%' }}>Calculate Limit</Button>,
            key: '0',
        },

        {
            label: <Button type='primary' onClick={() => { props?.onRefresh() }} ghost style={{ width: '100%' }}>Reload Graph</Button>,
            key: '1',
        },
    ];




    return (
        <div>
            {
                props?.user?.permissions?.can_add_spc_on_web == true ? (
                    <div className="header" style={{ width: '100%', height: 45, backgroundColor: '#ccc', padding: 5, textAlign: 'right' }}>
                        {/* <Button type="primary" onClick={() => {
                            setModalVisible(true);
                            loadInitialValues();
                        }}>Add New</Button> */}

                        <Space direction="vertical">
                            <Dropdown.Button
                                type="primary"
                                onClick={() => {
                                    setModalVisible(true);
                                    loadInitialValues();
                                }}
                                menu={{
                                    items,
                                }}
                            >
                                Add New
                            </Dropdown.Button>
                        </Space>



                        <Modal
                            // forceRender
                            closable={true}
                            open={modalVisible}
                            title={"Add New Data"}
                            onOk={handleOnOk}
                            onCancel={clearData}
                            centered={true}
                            destroyOnClose={true}
                            footer={
                                [
                                    <Button key="back" onClick={clearData}>
                                        Cancel
                                    </Button>,
                                    <Button key="submit" type="primary" loading={submitting} onClick={handleOnOk}>
                                        Submit
                                    </Button>,
                                ]
                            }
                        >
                            <Spin tip="Loading..." spinning={loading}>
                                <div>
                                    <table className='table table-bordered'>
                                        <tbody>
                                            <tr>
                                                <td>Cell No</td>
                                                <td>{spc?.cell_number}</td>
                                                <td>Characterstic</td>
                                                <td>{spc?.characteristic}</td>
                                            </tr>
                                            <tr>
                                                <td>Min</td>
                                                <td>{spc?.min_value}</td>
                                                <td>Max</td>
                                                <td>{spc?.max_value}</td>
                                            </tr>
                                            <tr>
                                                <td>LSL</td>
                                                <td>{spc?.lsl}</td>
                                                <td>USL</td>
                                                <td>{spc?.usl}</td>
                                            </tr>
                                            <tr>
                                                <td>Sample Size</td>
                                                <td>{spc?.sample_size}</td>
                                                <td>UCL/LCL Calculated at</td>
                                                <td>{spc?.limit_calculated_at != null ? new Date(spc?.limit_calculated_at).toLocaleString() : "-"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Form
                                    preserve={false}
                                    form={boardForm}
                                    initialValues={boardFormData}
                                    labelCol={{ span: 24 }}
                                    layout={"vertical"}
                                    wrapperCol={{ span: 24 }}
                                    autoComplete="off">

                                    <Form.Item label="Batch ID" name="batch_id" rules={[{ required: true, message: 'This field is required!' }]}>
                                        <Input autoFocus={false} />
                                    </Form.Item>
                                    <Form.Item label="Notes" name="notes">
                                        <Input autoFocus={false} />
                                    </Form.Item>
                                    <Form.Item label="Sample Data" name="sample_data" rules={[{ required: true, message: 'This field is required!' }]}>
                                        <Input autoFocus={true} />
                                    </Form.Item>
                                </Form>
                            </Spin>
                        </Modal>

                    </div>

                ) : null
            }
        </div >
    );
};

function mapStateToProps(state) {
    const { auth } = state;
    const { token, user } = auth;
    return {
        token, user
    }
}

export default connect(mapStateToProps)(AddSPC);