import { message } from 'antd';
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import AddSPC from '../../components/spc/add_spc';
import { appUrl } from '../../redux/services/base';
import SPCService from "../../redux/services/spc";
const key = "updatable"
const SPCGraph = (props) => {
	const { spc_id } = useParams();
	const [frameUrl, setFrameUrl] = useState(null);
	useEffect(() => {
		setFrameUrl(`${appUrl}/spc/graph/react?page=1&spc_id=${spc_id}&token=`);

	}, [spc_id]);
	const onSuccess = () => {
		setFrameUrl(`${appUrl}/spc/graph/react?page=1&spc_id=${spc_id}&token=&ts=${new Date().valueOf()}`);
	}

	const onDelete = () => {
		if (window.confirm('Are you sure want to delete last data point?')) {
			SPCService.deleteDataPoint(spc_id).then(res => {
				message.success({ content: res.data.message, key });
				onSuccess();
			}).catch(res => {
				message.error({ content: res.response.data.message, key });
			});
		}
	}
	const onCalculateLimit = () => {
		if (window.confirm('Are you sure want to calculate UCL/LCL?')) {
			SPCService.calculateLimit(spc_id).then(res => {
				message.success({ content: res.data.message, key });
				onSuccess();
			}).catch(res => {
				message.error({ content: res.response.data.message, key });
			});
		}
	}


	return (
		<div style={{ width: '100%', height: 'calc(100% - 2px)' }}>
			<AddSPC spcId={spc_id} onSuccess={onSuccess} onCalculateLimit={onCalculateLimit}
				onRefresh={onSuccess}
				onDelete={onDelete} />

			{
				frameUrl != null ? (
					<iframe src={frameUrl}
						style={{ width: '100%', height: 'calc(100% - 2px)', overflow: 'hidden', border: 'none' }} scrolling="yes">
					</iframe>
				) : null
			}
		</div>
	);
};



function mapStateToProps(state) {
	const { user } = state.auth;

	return {
		user
	}
}

export default connect(mapStateToProps)(SPCGraph);


// export default SPCGraph;