import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
// import LoginTabset from "./loginTabset";
import QRCodeComp from "./qr_code";


const Login = (props) => {
    const history = useHistory()
    useEffect(() => {
        if (props.token !== null) {
            history.push("/loading")
        }
    }, [props.token])


    return (
        <div className="page-wrapper">
            <div className="authentication-box">

                <Container>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <QRCodeComp />
                            {/* <LoginTabset /> */}

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    const { token } = state.auth;
    return {
        token
    }
}

export default connect(mapStateToProps)(Login);